<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">SwiftPlus</h1>
            </div>
        </div>
    </div>
    <div>
        <form v-if="clusterData && statementMonths.length > 0" novalidate @submit.prevent="viewFilter">
            <div class="row">
                <div class="col-md-4 mb-3">
                    <DropdownInput
                        label="Month"
                        v-model="statementMonth"
                        :v=0
                        :options="statementMonths"
                    />
                </div>
            </div>
            <div>
                <div>
                    <button class="btn btn-primary me-1" type="submit">View Statement</button>
                </div>
            </div>
        </form>
        <div v-if="clusterData && statementMonths.length ==0">
            <p>Thank you for signing up to SwiftPlus.</p>
            <p>It appears you are in your first month and we currently do not have any statements for you.</p>
            <p>Please check back next month to see your first statement.</p>
        </div>
        <div v-if="isShowPromo">
            <p>You are not currently enrolled in the SwiftPlus sponsorship.</p>
            <p>With SwiftPlus you can use your laboratory spend on selected products to claim a complimentary intra oral scanner of your choice for your practice.</p>
            <p>To further explore this opportunity and accelerate your practice's digital transformation at no additional expense, kindly click the provided link to schedule a consultation with our team.</p>
            <p><a href="https://calendly.com/swiftplus/sdg" target="_blank">https://calendly.com/swiftplus/sdg</a></p>
        </div>
        <Warning v-if="warning" v-bind:warning="warning" />
    </div>
</template>

<style scoped lang="scss">
    
</style>

<script>
    import api from "@/services/api";
    import moment from 'moment';
    import DropdownInput from '@/components/DropdownInput.vue';
    import Warning from '@/components/Warning.vue';

    export default {
        components: {   
            DropdownInput,
            Warning
        },
        data() {
            return {
                clusterData: null,
                isShowPromo: false,
                statementMonth: '',
                statementMonths: [],
                warning: null,
            }
        },
        methods: {
            fetchDetails: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.clusterData = null;
                    return;
                }
                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                };
                
                api
                .get('/api/v1/swift-plus/details', {
                    params: params
                })
                .then((response) => {
                    this.clusterData = response.data;
                    this.statementMonths = response.data.statementMonths;
                })
                .catch(() => {
                    this.isShowPromo = true;
                    console.log('Catch fetching Swift Plus error');
                });
            },

            viewFilter: async function()
            {
                var params = {};

                if(this.statementMonth.trim().length)
                {
                    var statementMonth = moment(this.statementMonth); 
                    var statementYear = moment(this.statementMonth); 
                    params.month = statementMonth.format('MM');
                    params.year = statementYear.format('yyyy');
                } else {
                    this.warning = 'Please choose a month';
                    return;
                }

                this.$router.push({ name: 'SwiftPlusStatement',  params: params });
            },

        },
        mounted () {
            this.fetchDetails(); 
        },
        watch: {
            '$store.state.currentPracticeId': function() {
                this.fetchDetails();
            }
        },
    }

</script>
