<template>
    <div class="alert alert-warning mb-0 mt-1">
        <span id="warning_text">{{ warning }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            warning: {
                required: true
            }
        }
    }
</script>

<style>
</style>